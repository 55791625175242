<template>
  <div class="bill-container">
    <div class="bill-header">
      <div class="header-top">
        <p>
          <span class="nowMoney">{{ loanResult.loanName ? loanResult.loanName : '飞逸美选' }}</span>
          <span class="loanNum">借款期数:{{ loanResult.loanLimit }}期 | 借款时间:{{ loanResult.gmtCreatedStr }}</span>
        </p>
        <p class="protocalNum">
          <span>借款编号：{{ loanResult.loanNo }}</span>
          <span class="seeLoan" @click="()=>JumpLink('/loanProtocol')">查看协议</span>
        </p>
      </div>
      <div class="header-bottom">
        <p class="loanMoney">借款金额(元)</p>
        <p class="redMoney">{{ $filters.formatMoney(loanResult.loanAmt, 2) }}</p>
      </div>
    </div>
    <!-- 待还期数 -->
    <div class="awaitLoan">
      <p class="loanTitle">
        <span class="square"></span>
        <span class="titleText">待还期数</span>
      </p>
      <div class="everyMoney">
        <div class="everyItem" v-for="(item,index) in loanResult.planList" :key="index">
          <p class="item-top">
            <span class="number">第<i>{{ item.planIndex }}</i>期</span>
            <span class="dateTime">{{ item.planDateStr }}</span>
          </p>
          <p class="item-middle">
            <span class="moneyNum">{{ item.planAmt }}</span>
            <span v-if="item.statusDes=='已逾期'" class="loanStatus1">已逾期</span>
            <span v-if="item.statusDes=='待还款'" class="loanStatus2">待还款</span>
            <span v-if="item.statusDes=='未到还款日'" class="loanStatus3">未到还款日</span>
          </p>
          <p class="nowText">当前应还(元)</p>
        </div>

      </div>
    </div>
    <div class="btn" @click="()=>JumpLink('/repayment')">去还款</div>
  </div>
</template>
<script>
import {onMounted, reactive, toRefs} from 'vue'
import {isEmptyObj} from '@/utils/index'
import {Toast} from 'vant'
import API from '@/server/api'
import {Jump} from '@/utils/routh'
import {useRoute} from 'vue-router'

export default {
  name: 'BillDetail',
  setup() {

    const state = reactive({
      loanNo: '',
      loanResult: {}
    })

    const Route = useRoute();

    // 初始化信息
    const init = async () => {
      try {
        const res = await API.getloanInfo({loanNo: state.loanNo})
        if (res.success) state.loanResult = res.data;
      } catch (err) {
        Toast(err.message)
        console.log(err.message)
      }
    }

    onMounted(async () => {
      if (!isEmptyObj(Route.query)) {
        const {loanNo} = Route.query
        if (loanNo) state.loanNo = loanNo
        localStorage.setItem('loanNos', loanNo)
        await init()
      }
    })

    // 跳转
    const JumpLink = (url, params) => {
      if (!params) params = {}
      switch (url) {
        case '/loanProtocol':
          params.num = 3
          break
      }
      Jump(url, params)
    }

    return {
      ...toRefs(state),
      JumpLink
    }
  }
}
</script>

<style scoped lang='less' src='./index.less'></style>
